<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px">
        <van-cell-group>
          <van-field
            v-model="datafrom.Name"
            required
            label="名称"
            placeholder="请输入名称"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.UnitPrice"
            label="单价"
            type="number"
            required
            placeholder="请输入单价（元）"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.BedDesc"
            label="床位描述"
            placeholder="请输入床位描述（如：1张大床1.8米）"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Area"
            label="面积(㎡)"
            placeholder="请输入面积（如：30）"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Floor"
            label="楼层(层)"
            placeholder="请输入楼层（如：6）"
          />
        </van-cell-group>
        <div class="filtersBox">
          <div class="labelClass" style="padding: 10px 6px 10px 0px">
            <div><span style="color: red">* </span>是否有窗</div>
          </div>
          <div style="padding: 10px 14px 6px 5px">
            <van-radio-group
              v-model="datafrom.IsWindows"
              direction="horizontal"
            >
              <van-radio name="1" class="radio">有窗</van-radio>
              <van-radio name="2" class="radio">无窗</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="filtersBox">
          <div class="labelClass" style="padding: 10px 6px 10px 0px">
            <div><span style="color: red">* </span>有无Wifi</div>
          </div>
          <div style="padding: 10px 14px 6px 5px">
            <van-radio-group v-model="datafrom.IsWifi" direction="horizontal">
              <van-radio name="1" class="radio">有（免费）</van-radio>
              <van-radio name="2" class="radio">无</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="filtersBox">
          <div class="labelClass" style="padding: 10px 6px 10px 0px">
            <div><span style="color: red">* </span>抽烟</div>
          </div>
          <div style="padding: 10px 14px 6px 5px">
            <van-radio-group v-model="datafrom.IsSmoke" direction="horizontal">
              <van-radio name="1" class="radio">可吸烟</van-radio>
              <van-radio name="2" class="radio">禁烟</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-cell-group>
          <van-field
            v-model="datafrom.GuestNum"
            required
            label="入住人数"
            placeholder="请输入建议入住人数（如：2-3人）"
          />
        </van-cell-group>
        <div class="filtersBox">
          <div class="labelClass" style="padding: 10px 6px 10px 0px">
            <div><span style="color: red">* </span>有无早餐</div>
          </div>
          <div style="padding: 10px 14px 6px 5px">
            <van-radio-group v-model="datafrom.IsMeal" direction="horizontal">
              <van-radio name="1" class="radio">有早餐</van-radio>
              <van-radio name="2" class="radio">无早餐</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-cell-group>
          <van-field
            v-model="datafrom.Food"
            label="食品饮品"
            placeholder="请输入食品饮品"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Cost"
            label="费用政策"
            placeholder="请输入费用政策"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Amenity"
            label="便利设施"
            placeholder="请输入便利设施"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Medium"
            label="媒体科技"
            placeholder="请输入媒体科技（如：电视，...）"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Bathroom"
            label="浴室配套"
            placeholder="请输入浴室配套（如：一次性拖鞋，...）"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Bathing"
            label="洗浴用品"
            placeholder="请输入洗浴用品（如：牙刷，牙膏，...）"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.Other"
            label="其他设施"
            placeholder="请输入其他设施（如：洗衣机，...）"
          />
        </van-cell-group>

        <div class="submit" style="padding: 10px 7px">
          <span style="color: #646566; font-size: 14px; padding-left: 10px"
            >照片</span
          >
        </div>
        <van-uploader
          v-model="ShopImgList"
          multiple
          class="imgs"
          :max-count="6"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true"
        />
      </div>
      <div
        style="
          position: fixed;
          bottom: 0px;
          width: 100%;
          background: white;
          padding: 15px 0;
        "
      >
        <div style="width: 100%; text-align: center">
          <van-button
            class="button"
            @click="postfrom(1)"
            style="width: 95%; border-radius: 5px"
            type="info"
            >提交</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { WxGetRoomType, HSaveRoomType } from "@/api/RealInfo";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      ocodelist: [],
      datafrom: {
        TypeId: 0, //房间Id
        HotelId: this.$route.params.hotelId, //民宿旅馆Id
        Name: "", // 名称
        UnitPrice: "", // 单价
        BedDesc: "", // 床位描述
        Area: "", // 面积
        Floor: "", // 楼层
        IsWindows: "1", // 是否有窗
        IsWifi: "1", // 有无Wifi
        IsSmoke: "1", // 是否允许抽烟
        GuestNum: "", // 入住人数
        IsMeal: "1", // 有无早餐
        Food: "", // 食品饮品
        Cost: "", // 费用政策
        Amenity: "", // 便利设施
        Medium: "", // 媒体科技
        Bathroom: "", // 浴室配套
        Bathing: "", // 洗浴用品
        Img: "", // 房间照片
        Other: "", // 其他设施
      },
      ImgsLists: [],
      ShopImgList: [],
    };
  },
  created() {
    this.getHotelRoom();
  },
  methods: {
    getHotelRoom: function () {
      console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          TypeId: 0, //房间Id
          HotelId: Number(this.$route.params.hotelId), //民宿旅馆Id
          Name: "", // 名称
          UnitPrice: "", // 单价
          BedDesc: "", // 床位描述
          Area: "", // 面积
          Floor: "", // 楼层
          IsWindows: "1", // 是否有窗
          IsWifi: "1", // 有无Wifi
          IsSmoke: "1", // 是否允许抽烟
          GuestNum: "", // 入住人数
          IsMeal: "1", // 有无早餐
          Food: "", // 食品饮品
          Cost: "", // 费用政策
          Amenity: "", // 便利设施
          Medium: "", // 媒体科技
          Bathroom: "", // 浴室配套
          Bathing: "", // 洗浴用品
          Img: "", // 房间照片
          Other: "", // 其他设施
        };
      } else if (this.$route.params.add == "1") {
        WxGetRoomType({
          openID: getOpenId(),
          typeId: this.$route.params.hotelId,
        }).then((res) => {
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            this.datafrom.IsWindows = res.data.data.IsWindows + "";
            this.datafrom.IsWifi = res.data.data.IsWifi + "";
            this.datafrom.IsSmoke = res.data.data.IsSmoke + "";
            this.datafrom.IsMeal = res.data.data.IsMeal + "";
            // console.log(res.data.data);
            //照片
            if (res.data.data.Img != "" && res.data.data.Img != null) {
              this.ImgsLists = res.data.data.Img.split(",");
              var arr = [];
              for (let i = 0; i < this.ImgsLists.length; i++) {
                const e = this.ImgsLists[i];
                arr.push({ url: e });
              }

              this.ShopImgList = arr;
              console.log(this.ShopImgList);
              this.datafrom.Img = res.data.data.Img;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            this.datafrom.Region = res.data.data.RName;
          }
        });
      }
    },
    //上传民宿图片
    ShopImgRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl[0]);
              // that.datafrom.Imgs.push(res.data.picurl[0]);
              that.ImgsLists.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete(file, detail) {
      console.log(file, detail);
      this.ShopImgList.splice(detail.index, 1);
      this.ImgsLists.splice(detail.index, 1);
      // this.ShopImgList = [];
      // this.datafrom.Imgs = "";
    },
    // 提交
    postfrom: function (row) {
      if (this.datafrom.Name == "") {
        Toast.fail("请输入名称");
        return false;
      }
      if (this.datafrom.UnitPrice == "") {
        Toast.fail("请输入单价");
        return false;
      }
      if (this.datafrom.GuestNum == "") {
        Toast.fail("请输入入住人数");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      this.datafrom.Img = this.ImgsLists + "";
      HSaveRoomType(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 状态选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 50px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
</style>